<template>
    <div class="modal fade" tabindex="-1" id="confirmModal" aria-labelledby="ConfirmModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ConfirmModalLabel">{{ $t('delete') }}</h5>
                    <button type="button" id="hideModalConfirm" class="close p-0 m-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <p class="confirm-text">{{ $t('alert_msg') }}</p>

                    <button type="button" class="btn btn-danger mx-2" @click="$emit('handleDelete','id')" :disabled="status" >{{$t('delete')}}</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('cancel')}}</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ConfirmDelete',
    props:['status']
}

</script>

<style>
.confirm-text {
    margin: 0 0 1rem !important;
    display: block !important;
}
</style>