<template>
    <div class="add-address">

        <form action="" @submit.prevent="addAddress">
            <div class="row">
                <div class="col-12">
                    <div class="form-group" :class="{ 'form-group--error': v$.form.name.$error }">
                        <label for="full_name">
                            {{ $t('full_name') }}
                            <span>*</span>
                        </label>
                        <input type="text" class="form-control" id="full_name" v-model="form.name">
                        <div class="text-danger mt-1 font-weight-bold error_msg"
                             v-if="v$.form.name.$error && form.name.length === 0">
                            {{ v$.form.name.$errors[0].$message }}
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group" :class="{ 'form-group--error': v$.form.mobile.$error }">
                        <label for="phone_number">
                            {{ $t('Phone') }}
                            <span>*</span>
                        </label>
                        <input type="tel" class="form-control" id="phone_number" v-model="v$.form.mobile.$model">
                        <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.mobile.$error">
                            {{ v$.form.mobile.$errors[0].$message }}
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group" :class="{ 'form-group--error': v$.form.country_id.$error }">
                        <label>
                            {{ $t('Country') }}
                            <span>*</span>
                        </label>
                        <select v-model="form.country_id" class="form-control">
                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group" :class="{ 'form-group--error': v$.form.city_id.$error }">
                        <label>
                            {{ $t('city') }}
                            <span>*</span>
                        </label>
                        <select v-model="form.city_id" class="form-control">
                            <option v-for="city in cities" :key="city.id" :value="city.id">
                                {{ city.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label for="map">
                            {{ $t('map') }}
                            <span>*</span>
                        </label>
                        <input type="text" class="form-control" id="map" @click="findLocation"  @focus="findLocation" :value="form.lat + ' ' + form.lng" :disabled="filled">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group" :class="{ 'form-group--error': v$.form.address.$error }">
                        <label for="manual_map">
                            {{ $t('manual_map') }}
                            <span>*</span>
                        </label>
                        <input type="text" class="form-control" id="manual_map" v-model="form.address">
                        <div class="text-danger mt-1 font-weight-bold error_msg" v-if="v$.form.address.$error">
                            {{ v$.form.address.$errors[0].$message }}
                        </div>
                    </div>
                </div>

                <div class="col-md-8 col-12 mx-auto">
                    <button type="submit" class="btn w-100 btn-save" :disabled="loading ||!form.lat || !form.address">
                        <span v-if="!loading"> {{ $t('settings.add-location') }} </span>
                        <span class="loading" v-else>{{ $t('loading') }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import useValidate from '@vuelidate/core';
import {useToast} from "vue-toastification";
import {helpers, required} from "@vuelidate/validators";
import {getCountries} from "@/services/auth";
import {storeAddress} from "@/services/address";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
    name: "Add_address",
    data() {
        return {
            v$: useValidate(),
            toast: useToast(),
            cities:[],
            loading: false,
            filled:false,
            form: {
                name: '',
                mobile: '',
                country_id: '',
                city_id: '',
                address: '',
                lat: '',
                lng: ''
            }
        }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted: function () {
        this.form.country_id = store.getters.countries[0]?.id
    },
    watch:{
        'form.country_id' : function() {
            let userCountry = this.countries.find(country=>country.id === this.form.country_id);
            this.cities = userCountry.Cities;
        }
    },
    computed: {
        ...mapGetters({
            countries: "countries",
        }),
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    validations() {

        return {
            form: {
                name: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                },
                mobile: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                },
                address: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                },
                country_id: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                },
                city_id: {
                    required: helpers.withMessage(this.$t('required_msg'), required),
                },
            },
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        findLocation() {
            if (!navigator.geolocation) {
                this.toast.error('Geolocation is not supported by your browser',{timeout: 4000, closeButton: false,})
            } else {
                this.filled = true;
                navigator.geolocation.getCurrentPosition(this.successGeolocation, this.errorGeolocation);
            }

        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        successGeolocation(position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.form.lat = String(latitude);
            this.form.lng = String(longitude);
            console.log('latitude ', latitude)
            console.log('longitude ', longitude)

        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        errorGeolocation() {
            this.filled = false;
            this.toast.error(this.$t('error_msg'), {timeout: 4000, closeButton: false,})

        },
        addAddress() {
            this.loading = true;
            this.v$.$validate() // checks all inputs
            if (this.v$.$error) { // if ANY fail validation
                this.hasError = true
                this.loading = false;
                console.log(this.v$.error)
                return false
            }
            try {
                storeAddress(this.form).then((res) => {
                    this.loading = false;
                    if (res.data.status.status === 'fail') {
                        this.toast.error(this.$t('error_msg'), {timeout: 4000, closeButton: false,})
                    } else {
                        this.toast.success(this.$t('success_msg'));
                        this.$emit('handleToggle');
                    }
                }).catch(e => {
                    console.log(e)
                })

            } catch (error) {
                this.loading = false;
                console.error(error)
            }
        }
    }
}
</script>

