<template>
    <div class="address address-box">
        <label class="form-check-input" for="select_address">
            <input type="checkbox" name="user_address" :value="address.id" @change="$emit('getSelectedAddress',address.id)"
                   id="select_address" :checked="isChecked === address.id">
        </label>

        <span>
            <img src="@/assets/img/address.png" alt="">
        </span>
        <div class="address-details">
            <p style="margin-bottom: .5rem;">{{ address.name }}</p>
            <div>
                <p class="text-capitalize">{{ address.Country[0].name }}, {{ address.City[0].name }}</p>
                <p>{{ address.mobile }}</p>
            </div>
            <p>{{ address.address }}</p>
        </div>
        <button class="btn btn-delete btn-edit btn-delete--address" v-if="!preventDelete" type="button" @click="$emit('handleDelete')"
                :disabled="deleting"
                data-toggle="modal" data-target="#confirmModal">
            <span class="sr-only">delete address</span>
            <i class="far fa-trash-alt"></i>
        </button>


    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "AddressBox",
    props: ['address','isChecked','preventDelete'],

    data() {
        return {
            deleting: false,
            address_id: null
        }
    },
    computed: {
        ...mapGetters({
            user_address:"user_address"
        })

    }

}
</script>

<style scoped>
.custom-checkbox {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}


input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #FFF;
    margin: 0;
    font: inherit;
    color: #0E5AA6;
    width: 1rem;
    height: 1rem;
    border: 0.15em solid currentColor;
    transform: translateY(-0.075em);
    border-radius: 3px;
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: .75em;
    height: .75em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #fff;
    /* Windows High Contrast Mode */
    background-color: #0E5AA6;
}

input[type="checkbox"]:checked {
    background-color: #0E5AA6;
    border-color: transparent;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

</style>