import request from "@/helper/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAddresses(){
    return request({
        url:'/api/address',
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function storeAddress(data){
    return request({
        url:`/api/address/store`,
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function showAddress(address_id){
    return request({
        url: '/api/address/show',
        method: 'post',
        data: address_id
    })
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteAddress(address_id){
    return request({
        url: '/api/address/delete',
        method: 'post',
        data: address_id
    })
}