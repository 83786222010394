<template>
    <div style="background-color: #EEEFF2;padding: 3rem 0;margin-bottom: 2rem;">
    <div class="container-fluid text-center px-lg-4 custom-wrapper empty-box">
    <img src="@/assets/img/empty-cart.svg" class="empty" alt="">
        <h4 class="text-center empty_text">{{ $t('product.cart-empty')}}</h4>
    </div>
    </div>

</template>


<script>
    export default {
        name: 'Empty'
    }
</script>